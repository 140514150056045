<template>
  <div>
    <div class="mx-1">
      <b-collapse visible id="collapse-1">
        <b-form @submit.prevent="onSearch()">
          <b-overlay center :show="isLoading" rounded="sm">
            <b-card class="filter text-center mt-2">
              <div class="col-md-12">
                <h1 class="consumption-title">Monitor consumi eMessage</h1>
                <div class="consumption-wrapper" :data-status="status">
                  <svg
                    class="comsumption-tracker"
                    width="400"
                    height="200"
                    viewBox="0 0 120 75"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="dial-bar-bg"
                      d="M 10,70 A 50,50 0 0 1 110,70"
                      fill="none"
                      stroke="#e1ddda"
                      stroke-width="8"
                    ></path>
                    <path
                      class="dial-bar"
                      d="M 10,70 A 50,50 0 0 1 110,70"
                      fill="none"
                      stroke="#e1ddda"
                      stroke-width="5"
                      :stroke-dasharray="computedDashArray"
                    ></path>
                  </svg>
                  <span class="amount">{{ toLocaleCurrency(data) }}</span>
                  <span class="updated-at"
                    ><strong>credito del conto</strong></span
                  >
                </div>
              </div>
            </b-card>
            <template #overlay>
              <div class="text-center mt-9">
                <base-icon name="loading" width="35" height="35" />
                <p id="cancel-label">Operazione in corso...</p>
              </div>
            </template>
          </b-overlay>
        </b-form>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import LisaComunicaMixin from "@/mixins/LisaComunicaMixin";
import BaseIcon from "@/components/BaseIcon";
import { toLocaleCurrency } from "@/utils/strings";

export default {
  mixins: [CustomFiltersMixin, LisaComunicaMixin],
  data() {
    return {
      repository: "emessage",
      resource: "emessage",
      data: "—",
      isLoading: false,
      status: "off",
    };
  },
  components: {
    BaseIcon,
  },
  methods: {
    toLocaleCurrency,
    setTotalizerStatus() {
      if (this.data < 1) {
        this.status = "off";
      } else if (this.data < 11) {
        this.status = "alert";
      } else if (this.data > 10 && this.data < 50) {
        this.status = "warning";
      } else if (this.data > 50 && this.data < 100) {
        this.status = "peace";
      } else {
        this.status = "success";
      }
    },
  },
  computed: {
    computedDashArray() {
      const ret = this.data === "—" ? 0 : this.data * 1.6;
      return `${ret}, 1000`;
    },
  },
  created() {
    if (this.$store.state?.auth?.settings?.attribute_EMUSR_value) {
      const payload = {
        username: this.$store.state.auth.settings.attribute_EMUSR_value,
        password: this.$store.state.auth.settings.attribute_EMPWD_value,
      };
      this.credit_show(payload)
        .then((response) => {
          if (response?.data?.credit) this.data = response.data.credit;
          this.setTotalizerStatus();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
};
</script>

<style scoped>
.total {
  font: 15px;
  color: black;
}
.positive {
  background-color: rgb(33, 206, 33);
}
.negative {
  background-color: red;
}
:deep(.consumption-wrapper) {
  position: relative;
  margin-bottom: 50px;
}
:deep(.consumption-title) {
  font-size: 1.75rem;
  line-height: 2em;
  margin-bottom: 0;
}
:deep(.comsumption-tracker path) {
  transition: 400ms ease-in-out;
  stroke-linecap: round;
}
:deep(.amount) {
  position: absolute;
  top: calc(50% + (38px / 2) + 20px);
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 38px;
  display: block;
  line-height: 1em;
}
:deep(.updated-at) {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 22px;
  display: block;
  line-height: 1.4em;
}
:deep([data-status="off"] svg .dial-bar) {
  stroke: #e1ddda;
}
:deep([data-status="alert"] svg .dial-bar) {
  stroke: #ed3e39;
}
:deep([data-status="warning"] svg .dial-bar) {
  stroke: #ff9e01;
}
:deep([data-status="peace"] svg .dial-bar) {
  stroke: #43d260;
}
:deep([data-status="success"] svg .dial-bar) {
  stroke: #5bb55e;
}
</style>
